<template>
  <v-container style="height: 100%">
    <v-row justify="center" align-content="center" style="height: 100%">
      <v-col cols="12" sm="6" md="4">
        <h2 class="font-weight-medium mb-8 text-sm-center">Введіть код із СМС:</h2>
        <div class="ma-auto position-relative" style="max-width: 300px">
          <v-otp-input v-model="smsCode" length="4" :disabled="loading" @finish="onFinish"></v-otp-input>
          <v-overlay absolute :value="loading">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-overlay>
        </div>

        <div class="mt-8 text-sm-center">
          <v-btn class="mr-4" color="primary" @click="goBack"> Повернутись </v-btn>
          <v-btn color="error" @click="clear"> Очистити </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Verification',

  data: () => ({
    loading: false,
    smsCode: '',
  }),

  computed: {
    phone() {
      return this.$route.params.phone || ''
    },
  },

  methods: {
    ...mapActions('auth', ['verifyCode']),

    async onFinish() {
      this.loading = true
      const payload = {
        phone: this.phone,
        code: this.smsCode,
      }
      await this.verifyCode(payload)
      await this.$router.push({ name: 'Login', params: { phone: this.phone } })
      this.loading = false
    },
    goBack() {
      this.$router.go(-1)
    },
    clear() {
      this.smsCode = ''
    },
  },
}
</script>

<style scoped lang="scss"></style>
